import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Products from "./pages/Products";
import ProductDetails from "./pages/ProductDetails";

import Dashboard from "./Dashboard";
import Login from "./pages/auth/LoginPage";
import UserRegister from "./pages/auth/RegPage";
import Users from "./pages/auth/Users";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Logs from "./pages/Logs";
import Orders from "./pages/Orders";
import OrdersBooking from "./pages/OrdersBooking";
// import TraxCity from "./pages/CitiesTable";
import OrderDetails from "./pages/OrderDetails";
import Stores from "./pages/Stores";
import StoresDetails from "./pages/StoreDetails";
import StoreSaleReports from "./pages/StoreSaleReports";
import Couriers from "./pages/Couriers";
import LoadSheet from "./pages/LoadSheet";
import CourierDetails from "./pages/CourierDetails";
import Webhooks from "./pages/Webhooks";
import InventoryLogs from "./pages/InventoryLogs";
import VariantDetails from "./pages/VariantDetails";
import OrdersPaid from "./pages/OrdersPay";
import Clients from "./pages/admin/Clients";
import Charts from "./pages/charts";
const isAuthenticated = () => {
  // You can replace this with your token check logic
  return localStorage.getItem("token") !== null;
};

// Private route component
function PrivateRoute({ element, ...rest }) {
  return isAuthenticated() ? element : <Navigate to="/login" />;
}
const getUserRole = () => {
  // This is an example; you would typically parse the token or get this data from an API
  const user = JSON.parse(localStorage.getItem("user"));

  return user?.user.role; // Example: 'admin', 'orders', 'user', etc.
};
function RoleBasedRoute({ element, allowedRoles, ...rest }) {
  const userRole = getUserRole();

  return isAuthenticated() && allowedRoles.includes(userRole) ? (
    element
  ) : (
    <Navigate to="/login" />
  );
}

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Dashboard />}>
          <Route path="/products" element={<Products />}></Route>
          <Route
            path="/productdetails/:id"
            element={<ProductDetails />}
          ></Route>
          <Route path="/variant/:id" element={<VariantDetails />}></Route>
          <Route path="/webhooks" element={<Webhooks />}></Route>
          <Route path="/orders" element={<Orders />}></Route>
          <Route path="/orders/:id" element={<OrderDetails />}></Route>
          <Route path="/ordersbooking" element={<OrdersBooking />}></Route>
          <Route path="/orderdetails/:id" element={<OrderDetails />}></Route>
          <Route path="/traxcity" element={<TraxCity />}></Route>
          <Route path="/inventoryLogs" element={<InventoryLogs />}></Route>
          <Route path="/logs" element={<Logs />}></Route>
          <Route path="/stores" element={<Stores />}></Route>
          <Route path="/couriers" element={<Couriers />}></Route>
          <Route
            path="/courierdetails/:id"
            element={<CourierDetails />}
          ></Route>
          <Route path="/storesdetails/:id" element={<StoresDetails />}></Route>
          <Route path="/orders/paid" element={<OrdersPaid />}></Route>
        </Route> */}
        <Route path="/" element={<PrivateRoute element={<Dashboard />} />}>
          <Route
            path="/products"
            element={<PrivateRoute element={<Products />} />}
          ></Route>
          <Route
            path="/chart"
            element={<PrivateRoute element={<Charts />} />}
          ></Route>
          <Route
            path="/product/:id"
            element={<PrivateRoute element={<ProductDetails />} />}
          ></Route>

          <Route
            path="/variant/:sku"
            element={<PrivateRoute element={<VariantDetails />} />}
          ></Route>
          <Route
            path="/webhooks"
            element={<PrivateRoute element={<Webhooks />} />}
          ></Route>
          <Route
            path="/orders"
            element={<PrivateRoute element={<Orders />} />}
          ></Route>
          <Route
            path="/orders/:id"
            element={<PrivateRoute element={<OrderDetails />} />}
          ></Route>
          <Route
            path="/orders/booking/:store_id"
            element={<PrivateRoute element={<OrdersBooking />} />}
          ></Route>
          <Route
            path="/orderdetails/:id"
            element={<PrivateRoute element={<OrderDetails />} />}
          ></Route>
          {/* <Route
            path="/traxcity"
            element={<PrivateRoute element={<TraxCity />} />}
          ></Route> */}
          <Route
            path="/inventoryLogs"
            element={<PrivateRoute element={<InventoryLogs />} />}
          ></Route>
          <Route
            path="/logs"
            element={<PrivateRoute element={<Logs />} />}
          ></Route>
          <Route
            path="/stores"
            element={<PrivateRoute element={<Stores />} />}
          ></Route>
          <Route
            path="/couriers"
            element={<PrivateRoute element={<Couriers />} />}
          ></Route>
          <Route
            path="/loadsheet"
            element={<PrivateRoute element={<LoadSheet />} />}
          ></Route>
          <Route
            path="/courierdetails/:id"
            element={<PrivateRoute element={<CourierDetails />} />}
          ></Route>
          <Route
            path="/storesdetails/:id"
            element={<PrivateRoute element={<StoresDetails />} />}
          ></Route>
          <Route
            path="/salereports/:id"
            element={<PrivateRoute element={<StoreSaleReports />} />}
          ></Route>

          <Route
            path="/orders/paid"
            element={<PrivateRoute element={<OrdersPaid />} />}
          ></Route>
        </Route>
        {/* <Route path="/" element={<PrivateRoute element={<Dashboard />} />}>
          <Route
            path="/products"
            element={
              <RoleBasedRoute element={<Products />} allowedRoles={["admin"]} />
            }
          />
          <Route
            path="/productdetails/:id"
            element={
              <RoleBasedRoute
                element={<ProductDetails />}
                allowedRoles={["admin"]}
              />
            }
          />
          <Route
            path="/variant/:id"
            element={
              <RoleBasedRoute
                element={<VariantDetails />}
                allowedRoles={["admin"]}
              />
            }
          />
          <Route
            path="/webhooks"
            element={
              <RoleBasedRoute element={<Webhooks />} allowedRoles={["admin"]} />
            }
          />
          <Route
            path="/orders"
            element={
              <RoleBasedRoute
                element={<Orders />}
                allowedRoles={["admin", "orders"]}
              />
            }
          />
          <Route
            path="/orders/:id"
            element={
              <RoleBasedRoute
                element={<OrderDetails />}
                allowedRoles={["admin", "orders"]}
              />
            }
          />
          <Route
            path="/ordersbooking"
            element={
              <RoleBasedRoute
                element={<OrdersBooking />}
                allowedRoles={["admin"]}
              />
            }
          />
          <Route
            path="/orderdetails/:id"
            element={
              <RoleBasedRoute
                element={<OrderDetails />}
                allowedRoles={["admin", "orders"]}
              />
            }
          />
          <Route
            path="/traxcity"
            element={
              <RoleBasedRoute element={<TraxCity />} allowedRoles={["admin"]} />
            }
          />
          <Route
            path="/inventoryLogs"
            element={
              <RoleBasedRoute
                element={<InventoryLogs />}
                allowedRoles={["admin"]}
              />
            }
          />
          <Route
            path="/logs"
            element={
              <RoleBasedRoute element={<Logs />} allowedRoles={["admin"]} />
            }
          />
          <Route
            path="/stores"
            element={
              <RoleBasedRoute element={<Stores />} allowedRoles={["admin"]} />
            }
          />
          <Route
            path="/couriers"
            element={
              <RoleBasedRoute element={<Couriers />} allowedRoles={["admin"]} />
            }
          />
          <Route
            path="/courierdetails/:id"
            element={
              <RoleBasedRoute
                element={<CourierDetails />}
                allowedRoles={["admin"]}
              />
            }
          />
          <Route
            path="/storesdetails/:id"
            element={
              <RoleBasedRoute
                element={<StoresDetails />}
                allowedRoles={["admin"]}
              />
            }
          />
          <Route
            path="/orders/paid"
            element={
              <RoleBasedRoute
                element={<OrdersPaid />}
                allowedRoles={["admin", "orders"]}
              />
            }
          />
        </Route> */}

        <Route>
          <Route path="/register" element={<UserRegister />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/users" element={<Users />} />
        </Route>
        <Route>
          <Route path="/clients" element={<Clients />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
