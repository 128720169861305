import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Switch from "@mui/material/Switch";
import LinearProgress from "@mui/material/LinearProgress";
import api from "../hooks/apiClient";
const years = [2022, 2023, 2024, 2025];

// Sample months
const months = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const StoreSaleReports = () => {
  const { id } = useParams();
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [storeData, setStoreData] = useState([]);
  const [orderCount, setOrderCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingLine, setLoadingLine] = useState(false);

  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const fetchOrders = async () => {
    if (!selectedMonth || !selectedYear) {
      setSnackbar({
        children: "Please select both a month and a year.",
        severity: "warning",
      });
      return;
    }
    setLoading(true);
    try {
      const response = await api.get(
        `orders/sale/month/${id}?year=${selectedYear}&month=${selectedMonth}`
      );
      setStoreData(response.data.categories);
      setOrderCount(response.data.orderCount);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching orders:", error);
      setLoading(false);
      setSnackbar({
        children: "Server error no data availble",
        severity: "error",
      });
    }
  };

  const CategoriesTable = ({ categories }) => {
    return (
      <div>
        <h2>Order Categories / {orderCount}</h2>
        <table className="table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Total Orders</th>
              <th>Cancelled Orders</th>
              <th>Cancelled Percentage</th>
              <th>Fulfilled Orders</th>
              <th>Null Fulfilled</th>
              <th>Fulfilled & Cancelled</th>
              <th>Return Or Refused</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(categories).map((category) => {
              const categoryData = categories[category];
              return (
                <tr key={category}>
                  <td>{category}</td>
                  <td>{categoryData.total}</td>
                  <td>{categoryData.cancelled}</td>
                  <td className="table-danger">
                    {categoryData.cancelledPercentage}%
                  </td>
                  <td>{categoryData.fulfilled}</td>
                  <td>{categoryData.nullFulfilled}</td>
                  <td>{categoryData.fulfilledCancelled}</td>
                  <td className="table-danger">
                    {categoryData.returnRefused}%
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="container">
        <p>loading....</p>
      </div>
    );
  }
  return (
    <div className="container">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <h2>Filter Orders by Month and Year</h2>

      {/* Month Select */}
      <label className="form-label">
        Month
        <select
          className="form-control"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
        >
          <option value="">Select Month</option>
          {months.map((month) => (
            <option key={month.value} value={month.value}>
              {month.label}
            </option>
          ))}
        </select>
      </label>

      {/* Year Select */}
      <label>
        Year
        <select
          className="form-control ms-1"
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
        >
          <option value="">Select Year</option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </label>
      <button className="ms-3 btn btn-info" onClick={fetchOrders}>
        Get Report
      </button>
      <CategoriesTable categories={storeData} />
    </div>
  );
};

export default StoreSaleReports;
