import React, { useEffect, useState } from "react";

import { useParams, Link, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Switch from "@mui/material/Switch";
import LinearProgress from "@mui/material/LinearProgress";
import api from "../hooks/apiClient";
import "../pages/model.css";
// const api = axios.create({
//   baseURL: process.env.REACT_APP_API,
// });
const StoreDetail = ({ authToken }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [storeData, setStoreData] = useState();
  const [storeActive, setStoreActive] = useState("");
  const [newQuantity, setNewQuantity] = useState("");
  const [hookTopic, setHookTopic] = useState();
  const [hookAddress, SetHookAddress] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingLine, setLoadingLine] = useState(false);
  const [webhooks, setWebhooks] = useState([]);
  const [selectListChanged, setSelectListChanged] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const handelAtive = (e) => {
    setStoreActive(!storeActive);

    setSelectListChanged(!selectListChanged);
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get(`stores/${id}`, {
          headers: {
            token: authToken,
          },
        });

        setStoreData(response.data);
        setStoreActive(response.data.isActive);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching store data:", error);
        setSnackbar({
          children: "Server error no data availble",
          severity: "error",
        });
        setLoading(false);
      }
    };

    fetchData();
  }, [id, authToken]);

  const sendApprovalRequest = async () => {
    try {
      const response = await api.get(`stores/approval/${id}`, {
        headers: {
          token: authToken,
        },
      });

      setSnackbar({
        children: "Approvel request successfully approved",
        severity: "success",
      });
      setLoading(false);
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        setSnackbar({
          children: error.response.data,
          severity: "error",
        });
      } else {
        console.error("Error fetching store data:", error);
      }
      setLoading(false);
    }
  };

  const getWebhooks = async () => {
    setLoadingLine(true);
    try {
      const response = await api.get(`webhook/getwebhooks/${id}`, {
        headers: {
          token: authToken,
        },
      });
      // console.log(response.data);
      setWebhooks(response.data);
      setSnackbar({
        children: "get the webhooks",
        severity: "success",
      });
      setLoadingLine(false);
    } catch (error) {
      console.error("Error fetching store data:", error);
      setLoadingLine(false);
    }
  };
  const regWebhook = async () => {
    togglePopup();
    if (!hookAddress || !hookAddress) {
      setSnackbar({
        children: "Plz enter webhook topic and address",
        severity: "warning",
      });
      return;
    }
    setLoadingLine(true);
    try {
      const response = await api.post(
        `webhook/regwebhook/${id}`,
        {
          hookTopic,
          hookAddress,
        },
        {
          headers: {
            token: authToken,
          },
        }
      );

      setSnackbar({
        children: response.data.message,
        severity: "success",
      });
      setLoadingLine(false);
    } catch (error) {
      setSnackbar({
        children: `Error registering webhook ${error.response.data} `,
        severity: "error",
      });
      console.error("Error registering webhook :", error);
      setLoadingLine(false);
    }
  };
  const delWebhook = async (webhookId) => {
    setLoadingLine(true);

    try {
      const response = await api.post(
        `webhook/delwebhook/${id}`,
        { webhookId },
        {
          headers: {
            token: authToken,
          },
        }
      );

      setSnackbar({
        children: "Successfully delete webhook ",
        severity: "success",
      });
      setLoadingLine(false);
    } catch (error) {
      setSnackbar({
        children: "there is an error to delete webhooks",
        severity: "error",
      });
      setLoadingLine(false);
    }
  };
  const syncproducts = async () => {
    if (window.confirm("Are You sure you want to sync")) {
      setLoading(true);
      try {
        const response = await api.get(`products/shopifysync/${id}`, {
          headers: {
            token: authToken,
          },
        });

        setSnackbar({
          children: response.data,
          severity: "success",
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching store data:", error);
        setSnackbar({
          children: error.response.data,
          severity: "error",
        });
        setLoading(false);
      }
    }
  };
  const handleSaveData = async () => {
    if (!storeActive) {
      const userConfirmed = window.confirm(
        "Are you sure you want to deactivate?"
      );
      if (!userConfirmed) {
        return;
      }
    }

    setLoadingLine(true);
    try {
      const response = await api.put(
        `stores/active/${id}`,
        { isActive: storeActive },
        {
          headers: {
            token: authToken,
          },
        }
      );

      setSnackbar({
        children: response.data.message,
        severity: "success",
      });
      setSelectListChanged(!selectListChanged);
      setLoadingLine(false);
    } catch (error) {
      setSnackbar({
        children: error.response.data.message,
        severity: "error",
      });
      console.error(error);
      setLoadingLine(false);
      setStoreActive(!storeActive);
      setSelectListChanged(!selectListChanged);
    }
  };
  const handleNavClick = (id) => {
    navigate(`/salereports/${id}`);
  };
  if (loading) {
    return (
      <div className="container">
        <p>loading....</p>
      </div>
    );
  }
  return (
    <div className="container-fluid">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      {storeData && (
        <div className="d-flex flex-wrap justify-content-evenly">
          <div className="col-lg-5 col-xl-5 col-sm-10 shadow p-3 mb-5 bg-body rounded ">
            <div className="d-flex flex-column ">
              <div className="mt-5">
                <h6 className="">{storeData.name}</h6>
              </div>

              <div className="shadow-sm p-3 mb-5 bg-body rounded ">
                <p>Store Domain: {storeData.domain}</p>
                <p>Store Api: {storeData.api_key}</p>
                <p>Store Id: {storeData.id}</p>

                <p>Date: {storeData.created_at}</p>
              </div>
              <div className="shadow-sm p-3 mb-5 bg-body rounded ">
                {storeData.status === "Approved" ? (
                  <div>
                    <h5>Get New And Update Products</h5>
                    <button className="btn btn-info" onClick={syncproducts}>
                      Sync All Products
                    </button>
                  </div>
                ) : (
                  storeData.status
                )}
              </div>
            </div>
            <div>
              {webhooks.map((item, index) => (
                <div className="d-flex flex-column" key={index}>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                    onClick={() => delWebhook(item.id)}
                  >
                    Del Webhook
                  </button>
                  <span>ID: {item.id}</span>
                  <span>AD: {item.address}</span>
                  <span>CD: {item.created_at}</span>
                  <span>Format: {item.format}</span>
                  <span>Topic: {item.topic}</span>
                  <span>UD: {item.updated_at}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-4 col-xl-4 col-sm-10  p-3 mb-5 ">
            <div className="shadow p-3 mb-3 bg-body rounded d-flex flex-column">
              <div>
                <p className="float-start fw-bold">Status</p>
                <p className="float-end fw-lighter">
                  {storeData.status}
                  <i
                    onClick={() => alert("i cliked")}
                    class="bi bi-three-dots-vertical float-end"
                  ></i>
                </p>
              </div>
              {loadingLine ? <LinearProgress /> : ""}
              <div className="d-flex justify-content-between">
                <Switch
                  checked={storeActive}
                  onChange={handelAtive}
                  inputProps={{ "aria-label": "controlled" }}
                />
                {storeData.status === "Pending" ? (
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                    onClick={sendApprovalRequest}
                  >
                    <i className="bi bi-gear"></i> Approval and activate
                  </button>
                ) : (
                  selectListChanged &&
                  storeActive !== storeData.status && (
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        handleSaveData();
                      }}
                    >
                      <i className="bi bi-gear"></i> Save Data
                    </button>
                  )
                )}
              </div>
            </div>
            <div className="shadow p-3 mb-3 bg-body rounded d-flex flex-column ">
              <div className="mb-3">
                <h6 className="float-start fw-bold">Publishing</h6>
                <i
                  onClick={() => alert("i cliked")}
                  className="bi bi-three-dots-vertical float-end"
                ></i>
              </div>
              <div className="d-flex flex-column align-items-start ">
                <p className="fw-medium">Slaes Channels</p>
                <p>
                  <i className="bi bi-flower3"></i>
                  &nbsp; Velvourshop
                </p>
                <p>
                  <i className="bi bi-flower3"></i>
                  &nbsp; style hub
                </p>
                <p>
                  <i className="bi bi-flower3"></i>
                  &nbsp; websnotch
                </p>
                <p>
                  <i className="bi bi-flower3"></i>
                  &nbsp; vestile wear
                </p>
                <p>
                  <i className="bi bi-flower3"></i>
                  &nbsp; Noirs store online and offline
                </p>
              </div>
            </div>
            <div className="shadow p-3 mb-3 bg-body rounded ">
              <div className="d-flex  flex-column">
                <div className="">
                  <p className="fw-bold float-start">Insights</p>
                  <p className="fw-lighter float-end">Last 90 Days</p>
                </div>
                <div>
                  <p>
                    Sold 19 units to 17 customers for Rs14,335.00 in net sales.
                  </p>
                  <Link to={`/salereports/${id}`} className="float-start">
                    View Sales
                  </Link>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary"
                  onClick={getWebhooks}
                >
                  Get Webhooks
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary"
                  onClick={togglePopup}
                >
                  Reg Webhook
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showPopup && (
        <div className="modals">
          <div className="modal-contents">
            <div className="modal-header">
              <h6 className="modal-title">Enter WebHook</h6>
              <span onClick={togglePopup} className="close-btn">
                &times;
              </span>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <input
                  placeholder="hook/topic"
                  type="text"
                  className="form-control m-1"
                  name="HookTopic"
                  value={hookTopic}
                  onChange={(e) => setHookTopic(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  placeholder="WebHook URL.."
                  type="text"
                  className="form-control m-1"
                  name="HookAddress"
                  value={hookAddress}
                  onChange={(e) => SetHookAddress(e.target.value)}
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={togglePopup}
              >
                Close
              </button>
              <button
                onClick={regWebhook}
                type="button"
                className="btn btn-sm btn-outline-success m-1"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoreDetail;
